import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import { useSettings } from '../hooks/use-settings'
import { ProductInfo } from '../components/product-info'
import { Animate } from '../components/animate'

const Outer = styled.div`
  display: grid;
  grid-template-columns: 1fr var(--page-margin) calc(min(var(--page-max-width), calc(100vw - var(--page-margin) * 2)) * 0.5) calc(min(var(--page-max-width), calc(100vw - var(--page-margin) * 2)) * 0.5) var(--page-margin) 1fr;
  @media (max-width: 84rem){
    grid-template-columns: 1fr var(--page-margin) calc(min(var(--page-max-width), calc(100vw - var(--page-margin) * 2)) * 0.4) calc(min(var(--page-max-width), calc(100vw - var(--page-margin) * 2)) * 0.6) var(--page-margin) 1fr;
  }
  @media (max-width: 60rem){
    grid-template-columns: 1fr;
  }
`

const ImageContainer = styled.div`
  background-color: var(--gold);
  grid-column: span 3;
  display: grid;
  grid-template-columns: subgrid;
  height: 56rem;
  @media (max-width: 60rem){
    height: auto;
    padding: 4rem 0;
  }
  @media (max-width: 60rem){
    grid-column: span 1;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: none;
  }
`

const ImageContainerInner = styled.div`
  grid-column: 1 / span 3;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  @media (max-width: 60rem){
    grid-column: 1;
    opacity: 1;
  }
  .animate & {
    animation: ${fadeIn} 1.2s 0.8s ease-in-out both;
    @media (max-width: 40rem){
      animation: none;
    }
  }
  img {
    display: block;
    width: 24rem;
    height: auto;
    @media (max-width: 70rem){
      width: 20rem;
    }
    @media (max-width: 60rem){
      width: 18rem;
      max-width: 100%;
    }
  }
`

const InfoContainer = styled.div`
  grid-column: span 3;
  display: grid;
  grid-template-columns: subgrid;
  min-width: 30rem;
  @media (max-width: 60rem){
    min-width: 0;
    grid-column: span 1;
  }
`

const InfoContainerInner = styled.div`
  padding-left: 6.25rem;
  grid-column: 1;
  padding-top: 10rem;
  @media (max-width: 65rem){
    padding-left: 3rem;
  }
  @media (max-width: 60rem){
    padding: 3.75rem var(--page-margin) 0;
  }
`

type ProductPageProductSectionProps = {

}
export const ProductPageProductSection: FunctionComponent<ProductPageProductSectionProps> = () => {
  const {productInfo, productImage} = useSettings()
  if(!productInfo) return null
  return <Outer>
    <Animate>
      <ImageContainer>
        <ImageContainerInner>
          <img src={productImage} />
        </ImageContainerInner>
      </ImageContainer>
    </Animate>
    <InfoContainer>
      <InfoContainerInner>
        <ProductInfo />
      </InfoContainerInner>
    </InfoContainer>
  </Outer>
}