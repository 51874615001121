import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSettings } from '../hooks/use-settings'
import { isNotNullish } from '../utils/type-check'
import classNames from 'classnames'

const Outer = styled.div`
  position: fixed;
  bottom: var(--page-margin);
  right: var(--page-margin);
  background-color: var(--gold);
  border-radius: 10rem;
  font-family: forum, serif;
  z-index: 200;
  text-align: center;
  line-height: 1.25;
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid white;
  cursor: pointer;
  transition: transform 0.4s, opacity 0.4s;
  &:hover {
    transform: scale(1.1);
  }
  &.hide {
    opacity: 0;
  }
  span {
    font-size: 0.75em;
  }
`

type AddCartButtonProps = {

}
export const AddCartButton: FunctionComponent<AddCartButtonProps> = () => {
  const settings = useSettings()
  const [hide, setHide] = useState(false)
  const addToCart = async () => {
    const formData = new FormData()
    const csrfParamElement = document.querySelector<HTMLMetaElement>('meta[name="csrf-param"]')
    const csrfTokenElement = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')
    if(csrfParamElement && csrfTokenElement && isNotNullish(settings.productId)){
      formData.append(csrfParamElement.content, csrfTokenElement.content)
      formData.append('variant_id', settings.productId.toString())
      formData.append('quantity', '1')
      const res = await fetch('/shop/cart/add', {
        method: 'post',
        body: formData
      })
      location.href = res.url
    } else {
      alert('エラーが発生しました。')
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if(entries.some(entry => entry.isIntersecting)){
        setHide(true)
      } else {
        setHide(false)
      }
    })
    const footer = document.querySelector('#kalonear-footer')
    if(footer){
      observer.observe(footer)
    }
    
  }, [])
  return <Outer onClick={() => addToCart()} className={classNames({hide})}>
    ADD
    <span>TO</span>
    CART
  </Outer>
}