import React, { FunctionComponent } from 'react'
import { useBreakpoint } from '../hooks/use-break-point'

type SpacerProps = {
  length: string,
  mLength?: string,
  tLength?: string
}
export const HorizontalSpacer: FunctionComponent<SpacerProps> = ({length, mLength, tLength}) => {
  const isMobile = useBreakpoint('40rem')
  const isTablet = useBreakpoint('60rem')
  return <div style={{paddingLeft: isMobile ? mLength ?? tLength ?? length : isTablet ? tLength ?? length : length}} />
}
export const VerticalSpacer: FunctionComponent<SpacerProps> = ({length, mLength, tLength}) => {
  const isMobile = useBreakpoint('40rem')
  const isTablet = useBreakpoint('60rem')
  return <div style={{paddingTop: isMobile ? mLength ?? tLength ?? length : isTablet ? tLength ?? length : length}} />
}