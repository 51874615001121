import classNames from 'classnames'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  color: white;
`

type SpecTableData = {
  label: string,
  value: string
}

type SpecTableProps = {
  data: SpecTableData[]
}
export const SpecTable: FunctionComponent<SpecTableProps> = ({data}) => {
  return <Outer>
    {data.map((row, i) => <Item key={i} data={row}/>)}
  </Outer>
}

const ItemOuter = styled.div`
  border-top: 1px solid #454343;
  transition: padding 0.4s;
  &:nth-last-child(1) {
    border-bottom: 1px solid #454343;
  }
  &.open {
    padding-bottom: 3rem;
  }
`

const TitleRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  padding: 2rem 0;
  transition: padding 0.4s;
  ${ItemOuter}.open & {
    padding-top: 3rem;
  }
`

const Button = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.4s;
  &::before,
  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 1.25rem;
    background-color: white;
    margin: -0.5px 0;
    transition: transform 0.4s, opacity 0.4s;
  }
  &::before {
  }

  &::after {
    transition: transform 0.4s;
    transform: rotate(90deg);
  }
  ${ItemOuter}:hover & {
    &::before {
    }
    &::after {
    }
  }

  ${ItemOuter}.open & {
    transform: rotate(180deg);
    &::after {
      opacity: 0;
    }
  }
`

const Title = styled.div`
  font-size: 1.5rem;
  font-family: forum, serif;
  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: white;
    display: block;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 100% 100%;
    transition: transform 0.6s;
  }
  ${TitleRow}:hover &::after {
    transform: none;
    transform-origin: 0 0;
  }
  @media (max-width: 40rem){
    font-size: 1.375rem;
  }
`

const TitleLabel1 = styled.div`
  transform: none;
  transition: transform 0.6s, opacity 0.6s;
  ${TitleRow}:hover & {
    transform: translateY(90%) scaleY(0);
    opacity: 0;
  }
`

const TitleLabel2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-90%) scaleY(0);
  transition: transform 0.6s, opacity 0.6s;
  opacity: 0;
  ${TitleRow}:hover & {
    transform: none;
    opacity: 1;
  }
`

const ContentsOuter = styled.div`
  overflow: hidden;
  transition: height 0.4s, opacity 0.4s;
  opacity: 0;
  display: flex;
  align-items: center;
  ${ItemOuter}.open & {
    opacity: 1;
  }
`

const Contents = styled.div`
  font-size: 0.8125rem;
  line-height: 1.7;
  white-space: pre-wrap;
  @media (max-width: 40rem){
    font-size: 0.6875rem;
  }
  dl {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }
  dt {
  }
  dd {
    &::before {
      content: '：';
    }
  }
  img, video, iframe {
    display: block;
    width: 100%;
    height: auto;
  }
`

type ItemProps = {
  data: SpecTableData
}
const Item: FunctionComponent<ItemProps> = ({data}) => {
  const [open, setOpen] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)
  const contentRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const listener = () => {
      const content = contentRef.current
      if(content){
        const rect = content.getBoundingClientRect()
        setContentHeight(rect.height)
      }
    }

    const observer = new ResizeObserver(listener)
    const content = contentRef.current
    if(content){
      observer.observe(content)
    }
    listener()
  }, [])

  return <ItemOuter className={classNames({open})}>
    <TitleRow onClick={() => setOpen(v => !v)}>
      <Title>
        <TitleLabel1>
          {data.label}
        </TitleLabel1>
        <TitleLabel2>
          {data.label}
        </TitleLabel2>
      </Title>
      <Button />
    </TitleRow>
    <ContentsOuter style={{height: open ? contentHeight : 0}}>
      <Contents ref={contentRef} dangerouslySetInnerHTML={{__html: data.value}}/>
    </ContentsOuter>
  </ItemOuter>
}