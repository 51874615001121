import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import { SpecTable } from './spec-table'
import { useSettings } from '../hooks/use-settings'
import { isNotNullish } from '../utils/type-check'
import { Animate } from './animate'
import classNames from 'classnames'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: none;
  }

`

const Outer = styled.div``

const Type = styled.div`
  color: white;
  font-size: 0.8125rem;
  @media (max-width: 40rem){
    font-size: 0.688rem;
  }
`

const Name = styled.div`
  color: white;
  font-size: 2.250rem;
  font-family: forum, serif;
  margin-top: 1.25rem;
  @media (max-width: 40rem){
    font-size: 2rem;
    margin-top: 0.75rem;
  }
`

const NameJa = styled.div`
  color: white;
  font-size: 1rem;
  margin-top: 0.5rem;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
`

const Description = styled.div`
  color: white;
  font-size: 0.8125rem;
  line-height: 1.7;
  margin-top: 2rem;
  @media (max-width: 40rem){
    font-size: 0.688rem;
    margin-top: 1.5rem;
  }
`

const PriceContainer = styled.div`
  display: flex;
  margin-top: 2.5rem;
  @media (max-width: 60rem){
    flex-direction: row;
  }
  @media (max-width: 40rem){
    margin-top: 1.5rem;
  }
`

const PriceItem = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  & + & {
    border-left: 1px solid var(--gold);
    margin-left: 1rem;
    padding-left: 1rem;
    @media (max-width: 60rem){
      margin-top: 0;
      border-left-width: 1px;
      margin-left: 1rem;
      padding-left: 1rem;
    }
  }
`

const PriceItemInner1 = styled.div`
  color: var(--gold);
  display: flex;
  align-items: center;
  @media (max-width: 60rem){
    flex-wrap: wrap;
    align-items: center;
  }
  @media (max-width: 40rem){
    row-gap: 0.5rem;
  }
`


const PriceLabel1 = styled.div`
  font-size: 0.8125rem;
  @media (max-width: 60rem){
    
    text-align: center;
    flex: 1 1 100%;
  }
  @media (max-width: 40rem){
    font-size: 0.688rem;
  }
`

const Price1 = styled.div`
  font-size: 1.625rem;
  font-family: forum, serif;
  margin-left: auto;
  margin-top: 0.175rem;
  position: relative;
  @media (max-width: 60rem){
    margin-left: 0;
    text-align: center;
    flex: 1 1 100%;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 40rem){
    font-size: 1.375rem;
  }
  &.del::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    background-color: currentColor;
    height: 0.125rem;
    transform: translateY(-50%);
  }
`

const PriceTaxLabel1 = styled.span`
  font-size: 0.688rem;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  @media (max-width: 40rem){
    font-size: 0.563rem;
  }
`

const PriceItemInner2 = styled.div`
  color: var(--gold);
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 60rem){
    flex: 1 1 auto
  }
`

const PriceLabel2 = styled.div`
  font-size: 0.8125rem;
  line-height: 1.5;
  @media (max-width: 60rem){
    text-align: center;
  }
  span {
    white-space: nowrap;
  }
`

const Price2 = styled.div`
  font-size: 3.125rem;
  font-family: forum, serif;
  margin-top: 0.5rem;
  white-space: nowrap;
  @media (max-width: 60rem){
    text-align: center;
    font-size: 2rem;
    margin-top: auto;
    padding-top: 0.5rem;
  }
  @media (max-width: 40rem){
    font-size: 1.75rem;
  }
`

const PriceTaxLabel2 = styled.span`
  font-size: 1rem;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  @media (max-width: 60rem){
    font-size: 0.75rem;
  }
`

const CartButton = styled.div`
  color: var(--black);
  background-color: var(--gold);
  font-size: 1rem;
  font-weight: 500;
  padding: 1.5rem;
  margin-top: 1.25rem;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  .animate & {
    animation: ${fadeIn} 1s 0.4s both;
  }
  @media (max-width: 40rem){
    font-size: 0.938rem;
    padding: 1rem;
    margin-top: 1rem;
  }
`

const Note = styled.div`
  color: #888888;
  font-size: 0.750rem;
  line-height: 1.7;
  margin-top: 2rem;
  @media (max-width: 40rem){
    font-size: 0.625rem;
    margin-top: 1.25rem;
  }
`

const SpecTableContainer = styled.div`
  margin-top: 4rem;
`


type ProductInfoProps = {
}
export const ProductInfo: FunctionComponent<ProductInfoProps> = () => {
  const settings = useSettings()
  const data = settings.productInfo
  const addToCart = async () => {
    const formData = new FormData()
    const csrfParamElement = document.querySelector<HTMLMetaElement>('meta[name="csrf-param"]')
    const csrfTokenElement = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')
    if(csrfParamElement && csrfTokenElement && isNotNullish(settings.productId)){
      formData.append(csrfParamElement.content, csrfTokenElement.content)
      formData.append('variant_id', settings.productId.toString())
      formData.append('quantity', '1')
      const res = await fetch('/shop/cart/add', {
        method: 'post',
        body: formData
      })
      location.href = res.url
    } else {
      alert('エラーが発生しました。')
    }
  }
  if(!data) return null
  return <Outer>
    <Type dangerouslySetInnerHTML={{__html: data.type}} />
    <Name dangerouslySetInnerHTML={{__html: data.name}} />
    <NameJa dangerouslySetInnerHTML={{__html: data.nameJa}} />
    <Description dangerouslySetInnerHTML={{__html: data.description}}>
    </Description>
    <Animate>
      <PriceContainer>
        <PriceItem>
          <PriceItemInner1>
            <PriceLabel1 dangerouslySetInnerHTML={{__html: data.price.label}} />
            <Price1 className={classNames({del: isNotNullish(data.price.discountValue)})}>
              ¥{data.price.value.toLocaleString('en-US')}
              <PriceTaxLabel1>
                （税込）
              </PriceTaxLabel1>
            </Price1>
          </PriceItemInner1>
          {
            isNotNullish(data.price.discountValue) && <PriceItemInner2>
              <PriceLabel2 dangerouslySetInnerHTML={{__html: data.price.discountLabel?.split(' ').map(s => `<span>${s}</span>`).join(' ') ?? ''}} />
              <Price2>
                ¥{data.price.discountValue.toLocaleString('en-US')}
                <PriceTaxLabel2>
                （税込）
                </PriceTaxLabel2>
              </Price2>
            </PriceItemInner2>
          }
        </PriceItem>
        {
          data.installmentPrice &&
          <PriceItem>
            <PriceItemInner1>
              <PriceLabel1 dangerouslySetInnerHTML={{__html: data.installmentPrice.label}} />
              <Price1 className={classNames({del: isNotNullish(data.installmentPrice.discountValue)})}>
                ¥{data.installmentPrice.value.toLocaleString('en-US')}
                <PriceTaxLabel1>
                  （税込）
                </PriceTaxLabel1>
              </Price1>
            </PriceItemInner1>
            {
              isNotNullish(data.installmentPrice.discountValue) && <PriceItemInner2>
                <PriceLabel2 dangerouslySetInnerHTML={{__html: data.installmentPrice.discountLabel?.split(' ').map(s => `<span>${s}</span>`).join(' ') ?? ''}} />
                <Price2>
                  ¥{data.installmentPrice.discountValue.toLocaleString('en-US')}
                  <PriceTaxLabel2>
                  （税込）
                  </PriceTaxLabel2>
                </Price2>
              </PriceItemInner2>
            }
          </PriceItem>
        }
      </PriceContainer>
    </Animate>
    <Animate>
      <CartButton onClick={addToCart} className='add-to-cart'>
        カートに追加する
      </CartButton>
    </Animate>
    <Note dangerouslySetInnerHTML={{__html: data.note}} />
    <SpecTableContainer>
      <SpecTable
        data={data.specTable}
      />
    </SpecTableContainer>
  </Outer>
}