import React from 'react';
import { FunctionComponent } from 'react';
import { createGlobalStyle, css } from 'styled-components';

const Css = createGlobalStyle`
  :root, :host {
    all: unset;
    --page-margin: 5rem;
    --page-max-width: 90rem;
    @media (max-width: 75rem){
      --page-margin: 3rem
    }
    @media (max-width: 50rem){
      --page-margin: 2rem
    }
    @media (max-width: 40rem){
      --page-margin: 1.50rem;
    }
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
  .swiper-wrapper {
    /* transition-timing-function: cubic-bezier(0.25, 0, 0, 1)!important;
    transition-duration: 0.5s; */
  }
`

export const shadowOuterStyle = css`
  all: unset;
  line-height: 1;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-weight: 400;
`

export const RootCss: FunctionComponent = () => {
  return <>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css" />
    <Css />
  </>
}