import * as React from "react";
import type { SVGProps } from "react";
const SvgInstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 2.162c3.204 0 3.583.012 4.849.07 1.17.053 1.805.249 2.228.413.56.218.96.478 1.38.897.42.42.68.82.898 1.38.164.423.36 1.059.412 2.229.058 1.265.07 1.644.07 4.849s-.012 3.583-.07 4.848c-.053 1.17-.248 1.806-.412 2.229-.218.56-.478.96-.898 1.38-.42.42-.82.68-1.38.897-.423.164-1.058.36-2.228.413-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07c-1.17-.053-1.805-.249-2.228-.413a3.72 3.72 0 0 1-1.38-.897c-.42-.42-.68-.82-.898-1.38-.164-.423-.36-1.059-.413-2.229-.057-1.265-.07-1.644-.07-4.848s.013-3.584.07-4.85c.054-1.17.25-1.805.413-2.228.218-.56.478-.96.898-1.38.42-.42.82-.68 1.38-.897.423-.164 1.058-.36 2.228-.413 1.265-.058 1.645-.07 4.849-.07M12 0C8.74 0 8.332.014 7.052.072 5.775.131 4.902.333 4.14.63a5.878 5.878 0 0 0-2.126 1.384A5.881 5.881 0 0 0 .63 4.14c-.297.763-.5 1.636-.558 2.913C.014 8.333 0 8.74 0 12s.014 3.668.072 4.948c.058 1.277.261 2.15.558 2.912.307.79.717 1.459 1.384 2.126A5.879 5.879 0 0 0 4.14 23.37c.763.297 1.635.5 2.912.558C8.332 23.986 8.741 24 12 24s3.668-.014 4.948-.072c1.276-.058 2.15-.261 2.912-.558a5.877 5.877 0 0 0 2.126-1.384 5.88 5.88 0 0 0 1.384-2.126c.296-.763.5-1.635.557-2.912.06-1.28.073-1.689.073-4.948s-.014-3.668-.072-4.947c-.059-1.277-.262-2.15-.558-2.913a5.878 5.878 0 0 0-1.384-2.126A5.882 5.882 0 0 0 19.86.63c-.763-.296-1.635-.5-2.913-.558C15.667.014 15.26 0 12 0"
      fill="currentcolor"
      strokeWidth="0px"
    />
    <path
      d="M12.001 5.838a6.162 6.162 0 1 0 0 12.323 6.162 6.162 0 0 0 0-12.323m0 10.162a4 4 0 1 1 0-8 4 4 0 0 1 0 8M18.404 7.034a1.44 1.44 0 1 0 0-2.88 1.44 1.44 0 0 0 0 2.88"
      fill="currentcolor"
      strokeWidth="0px"
    />
  </svg>
);
export default SvgInstagramIcon;
