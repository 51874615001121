type ProductInfoData = {
  type: string
  name: string
  nameJa: string
  description: string
  price: {
    label: string
    value: number
    discountLabel?: string
    discountValue?: number
  }
  installmentPrice?: {
    label: string
    value: number
    discountLabel?: string
    discountValue?: number
  }
  note: string
  specTable: {
    label: string
    value: string
  }[]
}
type Topic = {
  img: string
  title: string
  content: string
  slug: string
}
type Settings = {
  themeFileRootPath: string,
  productId?: number,
  productInfo?: ProductInfoData,
  productImage?: string,
  topics?: Topic[]
}
export const useSettings = (): Settings => {
  return Object.assign({}, ...Array.from(document.querySelectorAll('.settings')).map(el => {
    if(el.textContent){
      try {
        return JSON.parse(el.textContent)
      }catch(err){
        return {}
      }
    } else {
      return {}
    }
  }))
}